import {environment} from '../../../environments/environment';

const localJSON = './assets/json';
const { ADMIN_TOOL_URL } = environment;
/*
 ****** The index is sorted by alphabetical order for scan it faster,
 ****** please when you add a voice take a minute to add it in the right spot
 ****** keep the order!
 */
export const API = {
  ADMIN_TOOL: {
    userProfile: (username: string) => `${ADMIN_TOOL_URL}/tenants/current/users/${username}/profile`,

    tenants: (username: string, solution: string) => `${ADMIN_TOOL_URL}/users/${username}/solutions/${solution}/tenants`,

    assetGroupsSearch: () => `${ADMIN_TOOL_URL}/tenants/current/users/me/search-asset-groups`,

    roleFeatures: (role: string) =>
      `${ADMIN_TOOL_URL}/tenants/current/roles/${role}/platforms/CUBE/solutions/${environment.SOLUTION}/features`,

    roleMeasures: (role: string) =>
      `${ADMIN_TOOL_URL}/tenants/current/roles/${role}/platforms/CUBE/solutions/${environment.SOLUTION}/measureCategories`
  },
  AUDIT_MANAGEMENT: {
    auditManager: () => `${environment.REST}/AuditManagerHttpTrigger`,
  },
  CARBON_CREDIT: {
    calculateCredits: () => `${environment.REST}/CarbonCreditIngestionHttpTrigger/metrics`,
    carbonCreditIngestion: (id?: string) => id ? `${environment.REST}/CarbonCreditIngestionHttpTrigger?id=${id}` : `${environment.REST}/CarbonCreditIngestionHttpTrigger`,
    carbonCreditAuditManager: (id?: string, status?: string) => id && status ? `${environment.REST}/CarbonCreditAuditManagerHttpTrigger?id=${id}&status=${status}` :
      id ? `${environment.REST}/CarbonCreditAuditManagerHttpTrigger?id=${id}` : `${environment.REST}/CarbonCreditAuditManagerHttpTrigger`
  },
  CARBON_FUNCTION: {
    carbonFunction: (id?: string) => id ? `${environment.REST}/CarbonFunctionIngestionHttpTrigger?id=${id}` : `${environment.REST}/CarbonFunctionIngestionHttpTrigger`,
    productIDs: () => `${environment.REST}/CarbonFunctionIngestionHttpTrigger?distinct=product_id`,
    carbonFunctionAuditManger: (id?: string, status?: string) => id && status ? `${environment.REST}/CarbonFunctionAuditManagerHttpTrigger?id=${id}&status=${status}` :
      id ? `${environment.REST}/CarbonFunctionAuditManagerHttpTrigger?id=${id}` : `${environment.REST}/CarbonFunctionAuditManagerHttpTrigger`
  },
  CARBON_PRODUCTION: {
    carbonProduction: (id?: string) => id ? `${environment.REST}/CarbonProductionIngestionHttpTrigger?id=${id}` : `${environment.REST}/CarbonProductionIngestionHttpTrigger`,
    carbonProductionManager: (id: string, status: string) => `${environment.REST}/CarbonProductionStatusManagerHttpTrigger?id=${id}&production_status=${status}`
  },
  FILE_REPORT: {
    offlineFilesManager: (resource?: string, id?: string, url?: string) => url && resource ? `${environment.REST}/OfflineFilesManagerHttpTrigger/${resource}?url=${encodeURIComponent(url)}` :
      id && resource ? `${environment.REST}/OfflineFilesManagerHttpTrigger/${resource}?id=${id}` : `${environment.REST}/OfflineFilesManagerHttpTrigger`
  },
  ZPAGES: {
    // Get a response with system statistics
    varz: () => `${environment.REST}/varz`
  }
};
